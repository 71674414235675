/* eslint-disable global-require */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
require('./src/styles/global.css');

exports.onClientEntry = () => {
  // Create tab event so that we can mount :focused only when tabbing.
  const handleFirstTab = (e) => {
    if (e.keyCode === 9) {
      document.body.classList.add('isTabbing');
      document.removeEventListener('keydown', handleFirstTab);
    }
  };
  document.addEventListener('keydown', handleFirstTab);
  // callAnalyticsAPI()
};
