exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktorer-js": () => import("./../../../src/pages/aktorer.js" /* webpackChunkName: "component---src-pages-aktorer-js" */),
  "component---src-pages-arrangementer-js": () => import("./../../../src/pages/arrangementer.js" /* webpackChunkName: "component---src-pages-arrangementer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prosjekter-js": () => import("./../../../src/pages/prosjekter.js" /* webpackChunkName: "component---src-pages-prosjekter-js" */),
  "component---src-pages-publikasjoner-js": () => import("./../../../src/pages/publikasjoner.js" /* webpackChunkName: "component---src-pages-publikasjoner-js" */),
  "component---src-pages-ryddestatus-js": () => import("./../../../src/pages/ryddestatus.js" /* webpackChunkName: "component---src-pages-ryddestatus-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/ArticleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-component-preview-js": () => import("./../../../src/templates/ComponentPreview.js" /* webpackChunkName: "component---src-templates-component-preview-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/EventTemplate.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-guide-page-template-js": () => import("./../../../src/templates/GuidePageTemplate.js" /* webpackChunkName: "component---src-templates-guide-page-template-js" */),
  "component---src-templates-persons-page-template-js": () => import("./../../../src/templates/PersonsPageTemplate.js" /* webpackChunkName: "component---src-templates-persons-page-template-js" */),
  "component---src-templates-preview-js": () => import("./../../../src/templates/Preview.js" /* webpackChunkName: "component---src-templates-preview-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/ProjectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */)
}

